import { ChainId } from '@uniswap/sdk-core'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import getLibrary from '../utils/getLibrary'
import { getWeb3Connector } from './Binance/index'
import { FortmaticConnector } from './Fortmatic'
import { OKXConnector } from 'okx-connector'
import { NetworkConnector } from './NetworkConnector'
import { GateConnector } from './GateConnector'
import UNISWAP_LOGO_URL from '../assets/svg/logo.svg'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

const NETWORK_URLS = {
  [ChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [ChainId.GÖRLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [ChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [ChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [ChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  11155111: `https://sepolia.infura.io/v3/${INFURA_KEY}`,
}

const SUPPORTED_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.RINKEBY,
  ChainId.ROPSTEN,
  ChainId.KOVAN,
  ChainId.GÖRLI,
  56,
  97,
  8453,
  84531,
  1573,
  11155111,
]

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: ChainId.MAINNET,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const okxConnector = new OKXConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const gateConnector = new GateConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})
const Connector = getWeb3Connector()
export const binanceConnector = new Connector({
  lng: 'en-US',
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  infuraId: INFURA_KEY,
  rpc: {
    1: NETWORK_URLS[ChainId.MAINNET],
    56: 'https://bsc-dataseed1.binance.org/',
  },
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  infuraId: INFURA_KEY, // obviously a hack
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000,
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: '',
  appName: 'BakerySwap',
  appLogoUrl: UNISWAP_LOGO_URL,
  darkMode: false,
})
