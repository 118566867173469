import BigNumber from 'bignumber.js/bignumber'
const ChainId = {
  MAINNET: 1,
  GÖRLI: 5,
  BSC_MAINNET: 56,
  BSC_TESTNET: 97,
  BASE_GOERLI: 84531,
  BASE_MAINNET: 8453,
  SEPOLIA: 11155111,
}

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber('115792089237316195423570985008687907853269984665640564039457584007913129639935'), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const deadAddress = '0x000000000000000000000000000000000000dead'

export const contractAddresses = {
  iq50Stake: {
    [ChainId.MAINNET]: '0xe0e92545eD69fcD8ab0b1b21b94b8d5D68c6c30D',
    [ChainId.GÖRLI]: '0xf7f722A42b9182b159c993A07Bb48D8Bba0EeEc7',
  },
  bitcoinVMDrop: {
    [ChainId.BSC_MAINNET]: '',
    [ChainId.BSC_TESTNET]: '0x0c479316BC933FBCa9AEC7F85e66b7A7Ad41f7EC',
    [ChainId.MAINNET]: '',
    [ChainId.GÖRLI]: '0x39C17cd43478187d347015DB21aF3Ea9ee132988',
  },
  openSkyDrop: {
    [ChainId.BSC_MAINNET]: '0x5d92feB8FB391d7A7767e12E6f541397514f527b',
    [ChainId.BSC_TESTNET]: '0x2a0100B85c7E89a59717002d172C6373671CeD88',
    [ChainId.GÖRLI]: '0xE103faC7E927C715dd9fD1c778db7F5457040DDf',
    [ChainId.MAINNET]: '0x93f136B73bA8824f50b433904998050e17bd92D3',
  },
  catNFTDrop: {
    [ChainId.BSC_MAINNET]: '0x1594383D585Df7FE3DBfaadc25042Fd7838f26Fa',
    [ChainId.BSC_TESTNET]: '0x470483142Ff5D5508808ceE940D4E49fA242C8ac',
    [ChainId.GÖRLI]: '0x4cEfEf4D0411D4574A04E499284Cf4AFD9acD665',
    [ChainId.MAINNET]: '0xB867b86055144897954c3f37B07E60aF029f525D',
  },
  bendDaoDrop: {
    [ChainId.BSC_MAINNET]: '0xf52842616612F75595d085A63E1c897a16143619',
    [ChainId.BSC_TESTNET]: '0x27E73A3249B9Bc5833FafFb999B8BeA93C610494',
    [ChainId.GÖRLI]: '0xFec52FB3763326cc3E9e322B4Bb17BAC3bD8b927',
    [ChainId.MAINNET]: '0x8ba8DDa2EfeeE2b5BBdE54D464a0D69B3555A130',
  },
  bitCatDrop: {
    [ChainId.BSC_MAINNET]: '0xdf557Ea998BE90C912Cb9712a43e6144374ae8dD',
    [ChainId.BSC_TESTNET]: '0x095310FE6eC33d9f33d1884d8682D3cE1F718A9D',
    [ChainId.GÖRLI]: '0x3859EEE1935723E29ca415899bE71c4eC8117B42',
    [ChainId.MAINNET]: '0xA2B735a2770Dae2baFDe75Cff63171694f0973D4',
  },
  bitLandDrop: {
    [ChainId.BSC_MAINNET]: '0x6c704D798f58E0Fa4370Bd23962b40f9EC23892D',
    [ChainId.BSC_TESTNET]: '0x51636ea19996D5d42bC67c2Ea399c337574ABce5',
    [ChainId.GÖRLI]: '0xCFe207E4A1B8B796c4e5BAC8A8Cd150318069Cd2',
    [ChainId.MAINNET]: '0x956e778365c46d631E2613ec9c221b4ABcF69bdC',
  },
  oneCatToken: {
    [ChainId.GÖRLI]: '0xfB9F9154129B4A52E2d2465C0B2Ab99d6eD0865e',
    [ChainId.MAINNET]: '0x508E00D5ceF397B02d260D035e5EE80775e4C821',
  },
  oneCatDrop: {
    [ChainId.BSC_TESTNET]: '0x51B3AE3D1a7d5d99B94e8b1D3B8d364c829b8dE1',
    [ChainId.BSC_MAINNET]: '0x60225317a80619E7F7Dc0E9fFA54e4928f5C4e74',
    [ChainId.BASE_GOERLI]: '0x64A9E26eC102069843767Ef5f632388ad809Ee56',
    [ChainId.BASE_MAINNET]: '0xf45B4c35B8AFF528c54d6422Ad2bd6B94356aE70',
    [ChainId.GÖRLI]: '0x9c52D0bab431426C945d37De37Dc15D4cD269DfB',
    [ChainId.MAINNET]: '',
  },
  doggyCoinDrop: {
    [ChainId.GÖRLI]: '0x590EE025f1Cccb6B89fD4e706545921fAa7b5d4C',
    [ChainId.MAINNET]: '0x49352545a06071Bc841EeB18b9DDd602F234AF66',
  },
  punkxToken: {
    [ChainId.BSC_TESTNET]: '0x74F69E5BFFf895D53121A5038D6081c25AA47AEC',
    [ChainId.BSC_MAINNET]: '0x28eD8909de1b3881400413Ea970ccE377a004ccA',
    [ChainId.BASE_GOERLI]: '0x74F69E5BFFf895D53121A5038D6081c25AA47AEC',
    [ChainId.BASE_MAINNET]: '0x28eD8909de1b3881400413Ea970ccE377a004ccA',
  },
  doggyCoin: {
    [ChainId.BSC_TESTNET]: '0x1d1a6c18D1EFE9CA87C5cC08c9192F6969448a6F',
    [ChainId.BSC_MAINNET]: '0x0daDDB1729a846A75cc89e610eef590aD8b9F7ca',
    [ChainId.GÖRLI]: '0x20E8258d35f6Fca5688F0101a53993487C55090A',
    [ChainId.MAINNET]: '0x0daDDB1729a846A75cc89e610eef590aD8b9F7ca',
  },
  bakeryToken: {
    [ChainId.BSC_TESTNET]: '0xBADb0318b2ECbB1a00E3EFB6961bef059fb55E47',
    [ChainId.BSC_MAINNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  },
  // eth swap doggy nft
  swapNFT: {
    [ChainId.GÖRLI]: '0x0f62473F56a1f92B58Cb1AaFeadce25d91be54C2',
    [ChainId.MAINNET]: '0x47F4cDc7AB0040F6fBaa57599E674d73Fa0ba018',
  },
  doggyNFT: {
    [ChainId.GÖRLI]: '0x481cbD8b6af174d5476cC049dEEFEa5B1D21CEE0',
    [ChainId.MAINNET]: '0x82Ed14aE600eDa7346545fd5CBC68BA9338AEffA',
  },
  // swap pool
  token2NftBaseDrop: {
    [ChainId.GÖRLI]: '0xe357aFb3cdF80Ca1ac59d47CBE05879Db1211f6a',
    [ChainId.MAINNET]: '0x0a682E86900855DB50844BAf40ad2851684c2B82',
  },
  doggyToken: {
    [ChainId.GÖRLI]: '0x1d1a6c18D1EFE9CA87C5cC08c9192F6969448a6F',
    [ChainId.MAINNET]: '0xd2e4B429cBefAF2CaFA9731d66600C44bAc851A4',
  },
  wBTCToken: {
    [ChainId.GÖRLI]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    [ChainId.MAINNET]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  },
  transfer: {
    [ChainId.GÖRLI]: '0x626d260DaAf60D0c1C52E9eB8F166055d3Ee59CC',
    [ChainId.MAINNET]: '0xf8177Cda1b5B5B022F7a87546Dcb646C5562E952',
  },
  dish: {
    [ChainId.GÖRLI]: '0xC3a7736b284cE163c45D92b75da4d4A34847be63',
    [ChainId.MAINNET]: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  },
  sushi: {
    [ChainId.GÖRLI]: '0xaD376065D4fA9aBB6DDA0dED0EAdd911FE6E0f92', // TODO Not Working
    [ChainId.MAINNET]: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  },
  masterChef: {
    [ChainId.GÖRLI]: '0xaD376065D4fA9aBB6DDA0dED0EAdd911FE6E0f92', // TODO Not Working
    1: '0xc2edad668740f1aa35e4d8f227fb8e17dca888cd',
  },
  weth: {
    [ChainId.BSC_TESTNET]: '0x094616f0bdfb0b526bd735bf66eca0ad254ca81f', // WETH
    [ChainId.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    [ChainId.GÖRLI]: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    [ChainId.MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
  doggyBakeBaseDrop: {
    [ChainId.BASE_GOERLI]: '0xEb6BB6eA460079Df9e4e5615CF5B6C40ddEe3ff9',
    [ChainId.BASE_MAINNET]: '0x65Fca4426a3DBBAfE2b28354ab03821d29b35045',
  },
  reviewAddress: {
    [ChainId.BSC_TESTNET]: [
      '0xf04b7030Fd3787bE7ad8f1Cb13979690a831E9bC',
      '0x6fa3364792C9474b3B74A7d9A41D37be30c84D6B',
      '0x5425Ec0cBA7767C11cb5835aFaEE8DeB7C99f1BE',
      '0x4627a245f31A325F986FbB0528B04bF56977fD8A',
      '0x83B69ab825d3dd803ECc868198A08A8170eF97D4',
      '0x626d260DaAf60D0c1C52E9eB8F166055d3Ee59CC',
      '0x5e03bE5F99D3fE5b771711ac874344b41f4f1eD4',
      '0x8935dF9aa4856205526955159ffCa12DfB4EBb7f',
      '0x8fD1d21DF0E2b621c2E67c5043DDcFBd4B8E8ac5',
    ],
    [ChainId.BSC_MAINNET]: [
      '0xf04b7030Fd3787bE7ad8f1Cb13979690a831E9bC',
      '0x6fa3364792C9474b3B74A7d9A41D37be30c84D6B',
      '0x5425Ec0cBA7767C11cb5835aFaEE8DeB7C99f1BE',
      '0x4627a245f31A325F986FbB0528B04bF56977fD8A',
      '0x83B69ab825d3dd803ECc868198A08A8170eF97D4',
      '0x626d260DaAf60D0c1C52E9eB8F166055d3Ee59CC',
      '0x5e03bE5F99D3fE5b771711ac874344b41f4f1eD4',
      '0x8935dF9aa4856205526955159ffCa12DfB4EBb7f',
      '0x8fD1d21DF0E2b621c2E67c5043DDcFBd4B8E8ac5',
    ], // TODO
  },
}
