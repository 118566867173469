import React, { useContext, useState, useCallback, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'

// components
import { RowCenter } from 'components/Row'
// import ExchangePets from './ExchangePets'
import { ButtonConfirmed } from 'components/Button'
import Loader from 'components/Loader'
import BigNumber from 'bignumber.js'

// hooks
import { useActiveWeb3React } from 'hooks'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useTokenBalance } from 'state/wallet'
// import useHandleIdo from 'hooks/ido/useHandleIdo'
import useApproveLaunch from 'hooks/ido/useApproveLaunch'
import useAllowanceLaunch from 'hooks/ido/useAllowanceLaunch'

import { IDOTYPE } from 'constants/ido'
import { FnContext } from 'contexts/fnCallback'

import { getWeb3 } from 'utils/web3'
import Row from 'components/Row'
import {
  NETWORK_LABELS,
  NETWORKk_RPC_URL,
  NETWORKk_URL_MAP,
  TO_DEV_CHAINID,
  TO_MASTER_CHAINID,
  SUPPORTID,
  IDTOKEN,
  BAKERY_HOST_ORG,
} from 'constants/pet'
import { StorageEmitter, getAccountToken, localStore } from 'utils/tools'
import { useGetUserInfo, useLogin, useLoginTwitter } from 'hooks/useLogin'
import { contractAddresses } from 'sushi/lib/constants'
import BRIDGE_ABI from 'sushi/lib/abi/stake.json'
import { parseUnits } from '@ethersproject/units'
import { TransactionReceipt } from 'web3-eth'
// import NumericalInput from 'components/NumericalInput'
import useStaked from 'hooks/useStaked'
// import { ReactComponent as Star } from 'assets/svg/star.svg'
import { useUpdateUserInfo, useUserInfo } from 'state/twitter/hooks'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { bindSolanaAddress, fetchClaimParams, relieveBindSolanaAddress } from 'service/user'
import { useConnect } from 'hooks/useWallet'
import { useSolanaPublicKey, useUpdateSolanaPublicKey } from 'state/solana/hooks'
import { shortenSolAddress } from 'utils'
// import { ReactComponent as Copy } from 'assets/svg/copy.svg'
import { ReactComponent as IAddress } from 'assets/svg/address.svg'
import { ReactComponent as Close } from 'assets/svg/close.svg'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { TYPE } from 'theme'

import { Connection, PublicKey, SystemProgram, Transaction, ComputeBudgetProgram } from '@solana/web3.js'
import * as anchor from '@coral-xyz/anchor'
import { AnchorProvider, BN } from '@coral-xyz/anchor'
import { IDL } from 'utils/lib/idl/claim'
import { toArray } from 'utils'
import { getOrCreateAssociatedTokenAccount } from 'utils/getOrCreateAssociatedTokenAccount'
import { findClaimStatusKey } from 'utils/pda'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'

const Exchange = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  margin-top: 30px;
  padding-bottom: 28px;
  margin-bottom: 21px;
  border-bottom: 1px solid rgba(172, 85, 42, 0.1);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 21px;
    padding-bottom: 21px;
  `}
`
const StepText = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 16px;
  color: #ac552a;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  svg {
    margin-right: 7px;
  }
`

const TwitterName = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 16px;
  color: #ac552a;
  a {
    color: #ac552a;
  }
`

const StepWrapper = styled.div`
  background: #fffbef;
  width: 100%;
  padding: 20px 24px 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 17px 16px;
  `}
`

const Title = styled.div`
  font-family: 'Roboto-Medium';
  font-size: 14px;
  line-height: 168%;
  letter-spacing: 0.42px;
  margin-bottom: 10px;
  color: #ac552a;
  span {
    color: #722f0d;
    font-family: 'Roboto-Bold';
  }
`

const Desc = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 12px;
  line-height: 14px;
  color: #c58560cc;
`

const Address = styled.div`
  display: inline-flex;
  align-items: center;
  color: #722f0d;
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 119%;
  margin-top: 3px;
  word-break: break-all;
  div {
    margin-left: 8px;
    display: inline-block;
    svg {
      cursor: pointer;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    line-height: 161%;
    svg {
      margin-bottom: -6px;
    }
  `}
`

const SolAddress = styled.div`
  color: #722f0d;
  font-family: 'Roboto-Medium';
  font-size: 14px;
  line-height: 133%;
  margin-left: 6px;
  margin-right: 10px;
`

const FollowBtn = styled.button`
  border: none;
  padding: 0;
  width: 101px;
  height: 32px;
  border-radius: 5px;
  background: #ac552a;
  font-family: Baloo Da;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`

const StakeWrapper = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 12px;
  border: 1px solid #ac552a4d;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 11px;
  overflow: hidden;
  background: #fff;
  input {
    outline: none;
    border: none;
    margin-right: 16px;
    color: #ac552a;
    font-family: 'Roboto-Regular';
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    flex: 1;
    height: 100%;
    &::placeholder {
      color: rgba(172, 85, 42, 0.3);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 6px;
    input {
      line-height: 14px;
      font-size: 12px;
    }
  `}
`

const Max = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.primary1};
  font-size: 14px;
  padding: 0;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  letter-spacing: 0.01em;
  margin-right: 13px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`

const BtnConfirmed = styled(ButtonConfirmed)`
  width: 145px;
  height: 100%;
  padding: 0;
  border-radius: 11px;
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 18px;
  letter-spacing: 0em;
  border: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 12px auto 0;
    height: 46px;
    font-size: 16px;
    width: 100%;
  `}
`

const Data = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #c58560;
  word-break: break-all;
  span {
    font-family: 'Roboto-Bold';
    color: #ac552a;
  }
`

const BourseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 18px;
  `}
`

const BourseText = styled.div`
  display: flex;
  align-items: center;
  color: #ac552a;
  margin-bottom: 7px;
  span {
    font-family: 'Roboto-Medium';
    font-size: 14px;
    line-height: 19px;
    margin-right: 15px;
  }

  a {
    font-family: 'Roboto-Bold';
    font-size: 16px;
    line-height: 19px;
    color: #ac552a;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    a {
      font-size: 15px;
    }
  `}
`

const BourseList = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 54px;
  grid-row-gap: 7px;
  a {
    font-family: 'Roboto-Bold';
    font-size: 16px;
    line-height: 19px;
    color: #ac552a;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    a {
      font-size: 15px;
    }
  `}
`

const BourseItem = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  width: 107px;
  img {
    width: 49px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 97px;
    img {
      width: 43px;
    }
  `}
`

const Des = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.01em;
  white-space: pre-line;
  color: ${({ theme }) => theme.bsText38};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `}
`

const WalletBtn = styled(ButtonConfirmed)`
  width: 188px;
  height: 38px;
  padding: 0;
  border-radius: 6px;
  border: none;

  .okx {
    width: 52px;
    height: 20px;
  }
  .phantom {
    width: 98px;
    height: 19px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 45px;
    .okx {
      width: 55px;
      height: 24px;
    }
    .phantom {
      width: 102px;
      height: 20px;
    }
  `}
`

const Table = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 4px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex: initial;
    margin-top: 1px;
    overflow-x: scroll;
  `};
`
const TableHead = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  line-height: 14px;
  color: #c58560;
  border-radius: 8px 8px 0px 0px;

  span {
    flex: 1;
  }
  span:last-child {
    text-align: right;
    margin-right: 39px;
  }
  .assets {
    margin-left: 39px;
    min-width: 110px;
  }
  .amount {
    min-width: 120px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 363px;
    .assets {
      margin-left: 23px;
      min-width: 80px;
    }
    .amount {
      min-width: 90px;
    }
    span:last-child {
      margin-right: 23px;
      text-align: left;
    }
  `};
`
const TableBody = styled.div`
  flex: 1;
  div:last-child {
    border-radius: 0px 0px 8px 8px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 363px;
  `}
`

const TableTr = styled.div`
  display: flex;
  height: 54px;
  font-size: 13px;
  background: #fff;
  color: #ac552a;

  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.5);
  }
  & > div {
    flex: 1;
  }

  div:last-child {
    justify-content: flex-end;
    font-family: 'Roboto-Medium';
    margin-right: 39px;
  }
  .assets {
    margin-left: 34px;
    min-width: 115px;
    img {
      width: 23px;
      height: 23px;
      margin-right: 10px;
    }
  }
  .amount {
    min-width: 120px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
    .assets {
      margin-left: 20px;
      min-width: 83px;
    }
    .amount {
      min-width: 90px;
    }
    div:last-child {
      margin-right: 23px;
      justify-content: flex-start;
    }
  `};
`

const Name = styled(Row)`
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    height: 127px;
  }

  & > div {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #ac552a;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 100px;
    img {
      width: 200px;
      height: 53px;
    }
    & > div {
      font-size: 14px;
    }
  `};
`

const BindText = styled.div`
  color: #ac552a;
  font-size: 12px;
  line-height: 133%;
  margin-top: 10px;
  margin-bottom: 13px;
`

interface IdoSupportProps {
  type: any
  options: any
  dataInfo: any
  defaultSymbol?: any
  platform?: string
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const TradeList = [
  {
    href: 'https://www.gate.io/trade/1CAT_USDT',
    imgUrl: '/images/launchpad/gate.png',
    label: 'Gate.io',
  },
  {
    href: 'https://www.htx.com/en-us/trade/1cat_usdt?type=spot',
    imgUrl: '/images/launchpad/htx.png',
    label: 'HTX',
  },
  {
    href: 'https://www.bybit.com/trade/usdt/1CATUSDT',
    imgUrl: '/images/launchpad/bybit.png',
    label: 'Bybit',
  },
  {
    href: 'https://www.kucoin.com/trade/1CAT-USDT',
    imgUrl: '/images/launchpad/kucoin.png',
    label: 'Kucoin',
  },
  {
    href: 'https://www.bitget.com/spot/1CATUSDT',
    imgUrl: '/images/launchpad/bitget.png',
    label: 'Bitget',
  },
  {
    href: 'https://www.mexc.com/exchange/1CAT_USDT',
    imgUrl: '/images/launchpad/mexc.png',
    label: 'MEXC',
  },
]

export default function SupportCard({ type, options, dataInfo, defaultSymbol, platform }: IdoSupportProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const addPopup = useAddPopup()
  const [, loginFn] = useLogin()
  const toggleWalletModal = useWalletModalToggle()
  const updateSolanaPublicKey = useUpdateSolanaPublicKey()
  const userInfo = useUserInfo()
  const theme = useContext(ThemeContext)
  const callback: any = useContext(FnContext)
  const [, getUserInfoFn] = useGetUserInfo()
  const query = useQuery()
  const [copied, setCopied] = useCopyClipboard()
  const updateUserInfo = useUpdateUserInfo()
  const solanaPublicKey = useSolanaPublicKey()

  // const [solanaAddr, setSolanaAddr] = useState('')
  // const handleFetchSolanaAddress = useCallback(async () => {
  //   const res = await fetchUserSolanaAddress(chainId as number, account as string)
  //   if (res.code === 0 && res.data) {
  //     setSolanaAddr(res.data.solAddress)
  //     updateSolanaPublicKey(res.data.solAddress)
  //   }
  // }, [account, chainId, updateSolanaPublicKey])

  useEffect(() => {
    try {
      const status = query.get('status')
      if (status) {
        const msg: any = query.get('message')
        addPopup({
          txn: {
            hash: '',
            success: status === 'true' ? true : false,
            summary: status === 'true' ? 'Authorization complete' : msg ? msg : 'Authorization failed',
          },
        })
        window.location.href = window.location.origin + '/#/launchpad'
      }
    } catch (e) {
      console.log('Oauth feedback', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [baseAsset, setBaseAsset] = useState(defaultSymbol ? defaultSymbol : options[0])
  const [value, setValue] = useState<string | number>('')

  const [isLogin, setIsLogin] = useState(false)

  const oauthTwitterFn = useLoginTwitter()
  const [isTwitterLoading, setIsTwitterLoading] = useState(false)
  const handleTwitterAuth = useCallback(() => {
    if (!account) {
      toggleWalletModal()
      return
    }
    if (!isLogin) {
      loginFn()
      return
    }
    if (isTwitterLoading || userInfo?.twitterId) return
    setIsTwitterLoading(true)
    oauthTwitterFn()
    setIsTwitterLoading(false)
  }, [account, isLogin, isTwitterLoading, userInfo, oauthTwitterFn, toggleWalletModal, loginFn])

  const allowanceLaunch = useAllowanceLaunch(baseAsset.symbol.toLowerCase(), type)
    .div(new BigNumber(10).pow(18))
    ?.toString()

  const stakedNum = new BigNumber(useStaked(type))?.div(new BigNumber(10).pow(18))

  const updateValue = useCallback((value) => {
    setValue(value)
  }, [])

  const updateBaseAsset = useCallback(
    async (value: any) => {
      if (!account) {
        setBaseAsset(value)
        callback(value)
        updateValue('')
        return
      }
      if (
        (type === IDOTYPE.ONECAT &&
          ['bakery', 'eth', 'wbtc', 'doggycoin', 'punkx'].includes(value.symbol.toLowerCase())) ||
        ([
          IDOTYPE.BITLAND,
          IDOTYPE.BITCAT,
          IDOTYPE.BENDDAO,
          IDOTYPE.CATNFT,
          IDOTYPE.OPENSKY,
          IDOTYPE.BITCOINVM,
        ].includes(type) &&
          ['eth', 'wbtc', 'bakery', 'onecat'].includes(value.symbol.toLowerCase()))
      ) {
        const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
        const ethereum: any = library?.provider
        // console.log('d.symbol', value.symbol)
        if (chainId === toChainId[value.symbol.toLowerCase()]) {
          setBaseAsset(value)
          callback(value)
          updateValue('')
          return
        }

        const web3 = getWeb3(toChainId[value.symbol.toLowerCase()])
        await ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
              },
            ],
          })
          .then(() => {
            setBaseAsset(value)
            callback(value)
            updateValue('')
            // window.location.reload()
          })
          .catch(async (e: any) => {
            if ((e as any).code === 4902 || (e as any).code === -32603) {
              ethereum
                .request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
                      chainName: NETWORK_LABELS[toChainId[value.symbol.toLowerCase()]],
                      nativeCurrency: {
                        name: 'ETH',
                        symbol: 'ETH',
                        decimals: 18,
                      },
                      rpcUrls: [NETWORKk_RPC_URL[toChainId[value.symbol.toLowerCase()]]],
                      blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[value.symbol.toLowerCase()]]],
                    },
                  ],
                })
                .then(() => {
                  setBaseAsset(value)
                  callback(value)
                  updateValue('')
                  // window.location.reload()
                })
                .catch((e: any) => {
                  console.log(JSON.stringify(e))
                })
            } else if ((e as any).code === 4001) return
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [account, type, callback, updateValue, library?.provider, chainId]
  )

  const [requestedApprovalLaunch, setRequestedApprovalLaunch] = useState(false)
  const onApproveLaunch = useApproveLaunch(baseAsset.symbol.toLowerCase(), type)
  const handleApproveLaunch = useCallback(async () => {
    if (!SUPPORTID.includes(chainId as number)) {
      updateBaseAsset({
        symbol: 'ETH',
        name: 'eth',
        title: 'ETH',
        status: 0,
      })
      return
    }
    try {
      setRequestedApprovalLaunch(true)
      const txHash = await onApproveLaunch()
      if (!txHash) {
        setRequestedApprovalLaunch(false)
      } else {
        setTimeout(() => {
          setRequestedApprovalLaunch(false)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      setRequestedApprovalLaunch(false)
    }
  }, [chainId, onApproveLaunch, updateBaseAsset])

  // Get token balance
  const balance = useTokenBalance(baseAsset.symbol.toLowerCase())

  // buy
  const [requestedIdo, setRequestedIdo] = useState(false)
  const waitTransaction = useCallback(
    (txnHash: string | string[], options?: any) => {
      const interval = options && options.interval ? options.interval : 1000
      const transactionReceiptAsync = async function (
        txnHash: string,
        resolve: { (value: unknown): void; (arg0: TransactionReceipt): void },
        reject: { (reason?: any): void; (arg0: unknown): void }
      ) {
        const web3 = getWeb3(chainId as number)
        try {
          const receipt = await web3.eth.getTransactionReceipt(txnHash)
          if (!receipt) {
            setTimeout(function () {
              transactionReceiptAsync(txnHash, resolve, reject)
            }, interval)
          } else {
            resolve(receipt)
          }
        } catch (e) {
          reject(e)
        }
      }

      // Resolve multiple transactions once
      if (Array.isArray(txnHash)) {
        const promises: any[] = []
        txnHash.forEach(function (oneTxHash) {
          promises.push(waitTransaction(oneTxHash, options))
        })
        return Promise.all(promises)
      } else {
        return new Promise(function (resolve, reject) {
          transactionReceiptAsync(txnHash, resolve, reject)
        })
      }
    },
    [chainId]
  )

  const switchNetwork = useCallback(async () => {
    const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
    const ethereum: any = library?.provider
    if (chainId === toChainId[baseAsset.symbol.toLowerCase()]) return
    const web3 = getWeb3(toChainId[baseAsset.symbol.toLowerCase()])
    await ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
          },
        ],
      })
      .then(() => {
        // window.location.reload()
      })
      .catch(async (e: any) => {
        if ((e as any).code === 4902 || (e as any).code === -32603) {
          ethereum
            .request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
                  chainName: NETWORK_LABELS[toChainId[baseAsset.symbol.toLowerCase()]],
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: [NETWORKk_RPC_URL[toChainId[baseAsset.symbol.toLowerCase()]]],
                  blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[baseAsset.symbol.toLowerCase()]]],
                },
              ],
            })
            .then(() => {})
            .catch((e: any) => {
              console.log(JSON.stringify(e))
            })
        } else if ((e as any).code === 4001) return
      })
  }, [baseAsset.symbol, chainId, library?.provider])

  useEffect(() => {
    const token = getAccountToken(account as string)
    switchNetwork()
    if (token) {
      setIsLogin(true)
      getUserInfoFn()
    } else {
      setIsLogin(false)
      updateSolanaPublicKey('')
      updateUserInfo({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  useEffect(() => {
    StorageEmitter.on('storageSetItem', () => {
      const token = getAccountToken()
      if (token) {
        setIsLogin(true)
        getUserInfoFn()
        // handleFetchSolanaAddress()
      }
    })
    return () => {
      StorageEmitter.off('storageSetItem', () => {
        console.log('off')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLess = useCallback(() => {
    if (baseAsset?.min && +value < baseAsset?.min) {
      return true
    } else {
      if (
        !value ||
        !balance ||
        new BigNumber(value).lt(0) ||
        new BigNumber(balance).lt(0) ||
        new BigNumber(value).comparedTo(new BigNumber(0)) === 0 ||
        new BigNumber(balance).comparedTo(new BigNumber(0)) === 0
      ) {
        return true
      } else {
        if (
          new BigNumber(allowanceLaunch).lt(0) ||
          new BigNumber(allowanceLaunch).lt(value) ||
          new BigNumber(balance).lt(value)
        ) {
          return true
        } else {
          return false
        }
      }
    }
  }, [baseAsset?.min, value, balance, allowanceLaunch])

  useEffect(() => {
    if (account) {
      if (type === IDOTYPE.ONECAT && SUPPORTID.includes(chainId as number)) {
        let resetBaseAsset: any = baseAsset
        if (
          IDTOKEN['eth'].includes(chainId as number) &&
          !['eth', 'doggycoin'].includes(baseAsset.symbol.toLowerCase())
        ) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        if (IDTOKEN['punkx'].includes(chainId as number) && !['punkx'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[3]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
      if (
        [IDOTYPE.BITLAND, IDOTYPE.BITCAT, IDOTYPE.BENDDAO, IDOTYPE.OPENSKY, IDOTYPE.BITCOINVM].includes(type) &&
        SUPPORTID.includes(chainId as number)
      ) {
        let resetBaseAsset: any = baseAsset
        if (IDTOKEN['onecat'].includes(chainId as number) && !['onecat'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
      if ([IDOTYPE.CATNFT].includes(type) && SUPPORTID.includes(chainId as number)) {
        let resetBaseAsset: any = baseAsset
        if (IDTOKEN['eth'].includes(chainId as number) && !['eth', 'onecat'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account, type, callback, options])

  const handleStake = useCallback(async () => {
    setRequestedIdo(true)
    try {
      const { ethereum } = window
      const web3 = getWeb3(chainId as number)
      const contract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(BRIDGE_ABI)),
        contractAddresses.iq50Stake[chainId as number]
      )
      const data = contract.methods.bridge(parseUnits(value.toString())).encodeABI()
      const transferParam = {
        to: contractAddresses.iq50Stake[chainId as number],
        from: account,
        data,
        chainId: web3.utils.toHex(chainId as number),
      }
      ethereum
        ?.request({
          method: 'eth_sendTransaction',
          params: [transferParam],
        })
        .then(async (result: any) => {
          if (result) {
            const data: any = await waitTransaction(result)
            if (data.status) {
            }
            setRequestedIdo(false)
          }
        })
        .catch(function (reason: any) {
          if (reason.code === 4001) {
            console.log('Failed to transfer', reason.message)
          } else {
            console.log('Failed to transfer', reason)
          }
          addPopup({
            txn: {
              hash: '',
              success: false,
              summary: `Participate Failed`,
            },
          })
          setRequestedIdo(false)
        })
    } catch (e) {
      console.log('handleIdo', e)
      setRequestedIdo(false)
    }
  }, [account, addPopup, chainId, value, waitTransaction])

  const onMax = useCallback(() => {
    if (balance.toNumber() < 0) return
    const num = new BigNumber(balance).toString()
    updateValue(num)
  }, [balance, updateValue])

  const connect = useConnect()
  // const iq50Balance = useSolTokenBalance()
  const [okxLoading, setOkxLoading] = useState(false)
  const [phantomLoading, setPhantomLoading] = useState(false)
  const handleConnectSolanaWallet = useCallback(
    async (wallet_name) => {
      const { okxwallet, phantom } = window
      if (!account) {
        toggleWalletModal()
        return
      }
      if (!isLogin) {
        loginFn()
        return
      }

      if (!okxwallet && wallet_name === 'okx') {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Please Install OKX Wallet',
          },
        })
        return
      }
      if (!phantom && wallet_name === 'phantom') {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Please Install Phantom Wallet',
          },
        })
        return
      }
      if (wallet_name === 'okx') {
        setOkxLoading(true)
      }
      if (wallet_name === 'phantom') {
        setPhantomLoading(true)
      }
      const [publicKey, signature] = await connect(wallet_name, account)
      if (publicKey) {
        const res = await bindSolanaAddress(chainId as number, {
          solana: publicKey.toString(),
          signature: Buffer.from(signature).toString('base64'),
        })
        if (res?.code !== 0) {
          addPopup({
            txn: {
              hash: '',
              success: false,
              summary: res.message,
            },
          })
        } else {
          updateUserInfo({ ...userInfo, solana: publicKey.toString() })
        }
      }
      if (wallet_name === 'okx') {
        setOkxLoading(false)
      }
      if (wallet_name === 'phantom') {
        setPhantomLoading(false)
      }
    },
    [account, addPopup, chainId, connect, isLogin, loginFn, toggleWalletModal, updateUserInfo, userInfo]
  )

  const handleRelieveBind = useCallback(async () => {
    const res = await relieveBindSolanaAddress(chainId as number, account as string)
    if (res.code === 0) {
      updateUserInfo({ ...userInfo, solana: '' })
    }
  }, [account, chainId, updateUserInfo, userInfo])

  const handleClaim = useCallback(async () => {
    const network = window.location.host.indexOf('bakeryswap.org') < 0 ? 'mainnet' : 'devnet'
    const publicKey = new PublicKey(solanaPublicKey)
    const solanaWallet = localStore.get('Bakeryswap_solana_wallet')[account as string]
    const wallet = solanaWallet === 'okx' ? window.okxwallet.solana : window.solana
    const network_url = {
      devnet: 'https://api.devnet.solana.com',
      mainnet: 'https://florri-gvcn41-fast-mainnet.helius-rpc.com',
    }[network]
    const connection = new Connection(network_url)
    const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions())
    anchor.setProvider(provider)

    const programId = {
      devnet: new PublicKey('57MdA8RYcH6jWf4ZQnADECfXHPaoYnxVPtqiNTrTaSfN'),
      mainnet: new PublicKey('57MdA8RYcH6jWf4ZQnADECfXHPaoYnxVPtqiNTrTaSfN'),
    }[network]

    const tokenAccount = {
      devnet: new PublicKey('6JPHYtFDXoovTwStwhcinDyWteB18GAABQnTv8bE5CaV'),
      mainnet: new PublicKey('GbroKgDk9PqkE7tTtmVWrt9qeDvimJCXqGTnpiKEryux'),
    }[network]

    const res = await fetchClaimParams(chainId as number)
    if (res.code === 0) {
      const { claimId, signature, amount, distributor, recid } = res.data

      const program = new anchor.Program(IDL, programId)

      const [claimStatus] = await findClaimStatusKey(new BN(claimId), new PublicKey(distributor), programId)
      const [fromTokenAccount] = await getOrCreateAssociatedTokenAccount(
        connection,
        tokenAccount,
        new PublicKey(distributor),
        true
      )
      const [toTokenAccount, toTransaction] = await getOrCreateAssociatedTokenAccount(
        connection,
        tokenAccount,
        publicKey
      )

      const transaction = new Transaction()
      const claimTransaction = await program.methods
        .verification(new BN(claimId), new BN(amount), toArray(signature), recid)
        .accounts({
          distributor: new PublicKey(distributor),
          from: fromTokenAccount,
          toAta: toTokenAccount,
          payer: publicKey,
          claimStatus,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .transaction()

      if (toTransaction) {
        transaction.add(toTransaction)
      }

      transaction.add(claimTransaction)
      transaction.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 200000 }))
      transaction.add(ComputeBudgetProgram.setComputeUnitLimit({ units: 500000 }))
      const { blockhash: recentBlockhash } = await connection.getLatestBlockhash()
      transaction.recentBlockhash = recentBlockhash
      transaction.feePayer = publicKey

      try {
        const tx = await provider.sendAndConfirm(transaction)
        console.log('Your transaction signature', tx)
      } catch (err) {
        console.error('Error processing transaction', err)
      }
      // setClaimLoading(false)
    }
  }, [account, chainId, solanaPublicKey])

  const handleConnectTonWallet = useCallback(() => {
    const connector = new window.TonConnectSDK.TonConnect()

    connector.restoreConnection()
  }, [])

  return (
    <div>
      <Exchange>
        <Title>
          Follow <span>@bakery_swap</span> and <span>@PumpBigPump </span>
          on Twitter to get <span>1M $PUMP</span> if your have Twitter Blue
        </Title>
        {!userInfo?.twitterId ? (
          <Row marginTop="8px">
            <FollowBtn
              onClick={handleTwitterAuth}
              disabled={isTwitterLoading}
              style={userInfo?.twitterId ? { cursor: 'auto' } : {}}
            >
              {isTwitterLoading ? (
                <RowCenter>
                  <TYPE.body fontSize={isMobile ? 12 : 18} marginRight={'5px'}>
                    Loading
                  </TYPE.body>
                  <Loader stroke="white" />
                </RowCenter>
              ) : (
                'Follow'
              )}
            </FollowBtn>
          </Row>
        ) : userInfo.twitterInfo.verified ? (
          <>
            <TwitterName>
              twitter ID:{' '}
              <a href={`https://twitter.com/${userInfo.twitterInfo.username}`} target="_blank" rel="noreferrer">
                {userInfo.twitterInfo.username}
              </a>
            </TwitterName>
            <Row marginTop="8px" align="center">
              <img style={{ width: 27, height: 27, marginRight: 5 }} src="/images/launchpad/twitter_icon.png" />
              <TwitterName>1M $PUMP will be airdropped to your address</TwitterName>
            </Row>
          </>
        ) : (
          <>
            <TwitterName>
              twitter ID:{' '}
              <a href={`https://twitter.com/${userInfo.twitterInfo.username}`} target="_blank" rel="noreferrer">
                {userInfo.twitterInfo.username}
              </a>
            </TwitterName>
            <Row marginTop="8px">
              <TwitterName>You do not have Twitter Blue</TwitterName>
            </Row>
          </>
        )}
        <StepWrapper>
          <Title>2.Send $IQ50 to:</Title>
          {/*   <Address>
            Hoz7XLXdEvT2nyfG3mVzt1GGzKxVEpAaxzzZJxzdCReM
            <div>
              {!copied ? <Copy onClick={() => setCopied('Hoz7XLXdEvT2nyfG3mVzt1GGzKxVEpAaxzzZJxzdCReM')} /> : 'copied'}
            </div>
          </Address>
          <Table>
            <TableHead>
              <span className="assets">Assets</span>
              <span className="amount">Amount</span>
              <span>Points</span>
              <span>$PUMP</span>
            </TableHead>
            <TableBody>
              {holdList.length === 0 && !loading && (
                <EmptyBox>
                  <div>No Assets Found</div>
                </EmptyBox>
              )}
              {holdList.length > 0 &&
                holdList.map((d: any) => {
                  return (
                    <TableTr key={d.name}>
                      <Name className="assets">
                        <img src={`/images/launchpad/${d.name}.png`} />
                        <span>{d.name}</span>
                      </Name>
                      <Name className="amount">
                        {d.name === 'IQ50' && !solanaAddr ? '--' : addCommas(`${d.amount}`)}
                      </Name>
                      <Name>{d.points}</Name>
                      <Name>{d.name === 'IQ50' && !solanaAddr ? '--' : addCommas(`${d.pump}`)}</Name>
                    </TableTr>
                  )
                })}
            </TableBody>
              </Table>*/}
          <BindText>To receive $PUMP on BNB Chain for $IQ50, you need to bind your Solana Address</BindText>
          {userInfo?.solana ? (
            <Row align="center">
              <IAddress />
              <SolAddress>{shortenSolAddress(userInfo?.solana)}</SolAddress>
              <Close style={{ cursor: 'pointer' }} onClick={handleRelieveBind} />
            </Row>
          ) : (
            <Row align="center" style={{ gridColumnGap: isMobile ? '9px' : '11px' }}>
              <WalletBtn disabled={okxLoading} onClick={() => handleConnectSolanaWallet('okx')}>
                <img className="okx" src="/images/launchpad/okx.png" />
              </WalletBtn>
              <WalletBtn disabled={phantomLoading} onClick={() => handleConnectSolanaWallet('phantom')}>
                <img className="phantom" src="/images/launchpad/phantom.png" />
              </WalletBtn>
            </Row>
          )}
          <Row align="center" style={{ marginTop: 10 }}>
            <WalletBtn disabled={okxLoading} onClick={() => handleClaim()}>
              claim
            </WalletBtn>
          </Row>
          <Row align="center" style={{ marginTop: 10 }}>
            <WalletBtn disabled={okxLoading} onClick={handleConnectTonWallet}>
              Ton Wallet
            </WalletBtn>
          </Row>
        </StepWrapper>
        {/*<StepWrapper>
          <Title>3.Bridge $1CAT to 1CAT Chain</Title>
          <StakeWrapper>
            <NumericalInput fontSize={'15px'} placeholder="Enter quantity" value={value} onUserInput={updateValue} />
            <Max onClick={onMax}>MAX</Max>
            {!isMobile ? (
              !account ? (
                <BtnConfirmed onClick={toggleWalletModal}>Connect Wallet</BtnConfirmed>
              ) : !isLogin ? (
                <BtnConfirmed onClick={loginFn}>Login</BtnConfirmed>
              ) : new BigNumber(allowanceLaunch).gt(0) && !new BigNumber(allowanceLaunch).lt(value) ? (
                <BtnConfirmed
                  onClick={handleStake}
                  disabled={!(value && +value > 0) || balance.lt(value) || requestedIdo || isLess()}
                >
                  {requestedIdo ? (
                    <AutoRow gap="6px" justify="center">
                      Pending <Loader stroke="white" />
                    </AutoRow>
                  ) : balance.lt(value) ? (
                    'Insufficient Balance'
                  ) : (
                    'Stake'
                  )}
                </BtnConfirmed>
              ) : (
                <BtnConfirmed
                  onClick={() => handleApproveLaunch()}
                  disabled={requestedApprovalLaunch || balance.toNumber() <= 0}
                >
                  {requestedApprovalLaunch ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader stroke="white" />
                    </AutoRow>
                  ) : (
                    'Approve'
                  )}
                </BtnConfirmed>
              )
            ) : null}
          </StakeWrapper>
          {isMobile ? (
            !account ? (
              <BtnConfirmed onClick={toggleWalletModal}>Connect Wallet</BtnConfirmed>
            ) : !isLogin ? (
              <BtnConfirmed onClick={loginFn}>Login</BtnConfirmed>
            ) : new BigNumber(allowanceLaunch).gt(0) && !new BigNumber(allowanceLaunch).lt(value) ? (
              <BtnConfirmed
                onClick={handleStake}
                disabled={!(value && +value > 0) || balance.lt(value) || requestedIdo || isLess()}
              >
                {requestedIdo ? (
                  <AutoRow gap="6px" justify="center">
                    Pending <Loader stroke="white" />
                  </AutoRow>
                ) : balance.lt(value) ? (
                  'Insufficient Balance'
                ) : (
                  'Stake'
                )}
              </BtnConfirmed>
            ) : (
              <BtnConfirmed
                onClick={() => handleApproveLaunch()}
                disabled={requestedApprovalLaunch || balance.toNumber() <= 0}
              >
                {requestedApprovalLaunch ? (
                  <AutoRow gap="6px" justify="center">
                    Approving <Loader stroke="white" />
                  </AutoRow>
                ) : (
                  'Approve'
                )}
              </BtnConfirmed>
            )
          ) : null}
          <RowBetween marginTop={11} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
            {stakedNum && stakedNum.gte(0) && (
              <Data>
                Bridged $1CAT:&nbsp;<span>{stakedNum?.toFixed(2, BigNumber.ROUND_DOWN).toString()}</span>
              </Data>
            )}
            {balance.gte(0) && (
              <Data>
                Balance:&nbsp;
                <span>{balance?.toFixed(2, BigNumber.ROUND_DOWN).toString()} $1CAT</span>
              </Data>
            )}
          </RowBetween>
          <BourseWrapper>
            <BourseText>
              <span>Trade $1Cat on:</span>
              <a
                href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x508E00D5ceF397B02d260D035e5EE80775e4C821"
                target="_blank"
                rel="noreferrer"
              >
                <BourseItem>
                  <img src="/images/launchpad/uniswap.png" />
                  UNISWAP
                </BourseItem>
              </a>
            </BourseText>
            <BourseList>
              {TradeList.map((item) => {
                return (
                  <a href={item.href} target="_blank" rel="noreferrer" key={item.label}>
                    <BourseItem>
                      <img src={item.imgUrl} alt="logo" />
                      <span>{item.label}</span>
                    </BourseItem>
                  </a>
                )
              })}
            </BourseList>
          </BourseWrapper>
        </StepWrapper> */}
        {/* <StepWrapper>
          <Title>4.Participate in Pump Giveaways on Twitter.</Title>
          <Desc>
            {`We're launching a series of Pump Giveaway activities with various communities. If you're an influencer, DM us on Twitter.`}
          </Desc>
        </StepWrapper> */}
      </Exchange>
      <Des color={theme.bsText2}>{dataInfo.des}</Des>
      {dataInfo.illustration && (
        <img style={{ width: '100%', height: 'auto', marginTop: '20px' }} src={dataInfo.illustration} alt="" />
      )}
    </div>
  )
}
