export async function getBridgeInfo(
  lpContract: {
    _address: any
    methods: { getBridgeInfo: (arg0: any) => { (): any; new (): any; call: { (): any; new (): any } } }
  },
  account: any
) {
  if (!lpContract?._address) return 0
  try {
    return await lpContract.methods.getBridgeInfo(account).call()
  } catch (e) {
    console.error(e)
    return 0
  }
}
