import { get, post, del } from '../http'
import { getUrl } from './api'

// Login
export const login = async ({ chainId, params }: { chainId: number; params: any }) => {
  const url = getUrl(`/v1/user/login`, chainId)
  return await post(url, params, {
    ignoreToken: true,
  })
}

export const oauthTwitter = async (chainId: number, url: string) => {
  const apiUrl = getUrl(`/v1/twitter/oauth`, chainId, { url })
  return await get(apiUrl)
}

export const getUserInfo = async (chainId: number, account: string) => {
  const url = getUrl(`/v1/user/profile`, chainId)
  return await get(url, { account })
}

export const fetchUserPoints = async (chainId: number, account: string) => {
  const url = getUrl(`/v1/user/points`, chainId)
  return await get(url, { account })
}

export const syncPoints = async (chainId: number, account: string) => {
  const url = getUrl(`/v1/user/points/sync`, chainId)
  return await post(url, {}, { account })
}

export const fetchUserSolanaAddress = async (chainId: number, account: string) => {
  const url = getUrl(`/v1/user/solana/address`, chainId)
  return await get(url, { account })
}
export const submitSolanaAddress = async (chainId: number, params: any) => {
  const url = getUrl('/v1/user/solana/address', chainId)
  return await post(url, params)
}
export const bindSolanaAddress = async (chainId: number, params: any) => {
  const url = getUrl('/v1/user/solana/bind', chainId)
  return await post(url, params)
}

export const relieveBindSolanaAddress = async (chainId: number, account: string) => {
  const url = getUrl(`/v1/user/solana`, chainId)
  return await del(url, { account })
}

export const fetchClaimParams = async (chainId: number) => {
  const url = getUrl(`/v1/user/sol/claim`, chainId)
  return await post(url, {})
}
