import { useCallback, useEffect, useState } from 'react'
import { getBridgeInfo } from 'utils/lib/contract'

// hooks
import { useActiveWeb3React } from '.'
import useInterval from './useInterval'
import { getWeb3 } from 'utils/web3'

import { contractAddresses } from 'sushi/lib/constants'
import BRIDGE_ABI from 'sushi/lib/abi/stake.json'

const useStakedNum = (tokenName: string) => {
  const [reward, setReward] = useState(0)
  const { account, chainId } = useActiveWeb3React()
  const web3 = getWeb3(chainId as number)
  const stakedNum = useCallback(async () => {
    if (tokenName && account) {
      const contract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(BRIDGE_ABI)),
        contractAddresses.iq50Stake[chainId as number]
      )
      try {
        const stakeInfo = await getBridgeInfo(contract as any, account)
        setReward(stakeInfo?.amount)
      } catch (e) {
        console.log('useIdoPet', e)
      }
    }
  }, [account, chainId, tokenName, web3])

  useEffect(() => {
    if (account && tokenName) {
      stakedNum()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenName])

  useInterval(stakedNum, ![1, 5].includes(chainId as number) ? null : 10000)

  return reward
}

export default useStakedNum
